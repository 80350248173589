<template>
  <div class="intro-page">
    <slot />
  </div>
</template>

<script setup>

const { locale } = useI18n();

useHead({

  htmlAttrs: {
    lang: locale.value
  }
});

</script>
